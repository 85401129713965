<template>
  <div class="d-flex px-1" :class="{ 'v-event--thick': event.idEvento }" style="font-size: medium; font-weight: bold;white-space:normal;">
    {{ event.name }}
    <v-spacer></v-spacer>
  </div>
</template>

<script>
export default {
  props: {
    event: Object,
  },
};
</script>

<style>
</style>